<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h2>Cadastro de paciente</h2>
        <hr />
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="registerPaciente">
            <validation-provider
              v-slot="{ errors }"
              name="Nome completo"
              rules="required"
            >
              <v-text-field
                v-model="nomeCompleto"
                label="Nome completo (Obrigatório)"
                :error-messages="errors"
                required
              ></v-text-field>
            </validation-provider>

            <v-text-field
              v-model="nomeSocial"
              label="Nome social (Opcional)"
            ></v-text-field>

            <validation-provider name="Nome do pai">
              <v-text-field
                v-model="nomePai"
                label="Nome do pai (Opcional)"
              ></v-text-field>
            </validation-provider>

            <validation-provider name="Nome da mãe" rules="required">
              <v-text-field
                v-model="nomeMae"
                label="Nome da mãe (Obrigatório)"
                :error-messages="errors"
                required
              ></v-text-field>
            </validation-provider>

            <div class="row">
              <div class="col-md-3">
                <validation-provider name="UBS">
                  <v-text-field
                    v-model="ubs"
                    label="UBS (Opcional)"
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-3">
                <validation-provider name="Cartão SUS">
                  <v-text-field
                    v-model="sus"
                    label="Cartão SUS (Opcional)"
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-3">
                <validation-provider name="RG">
                  <v-text-field
                    v-mask="'##.###.###-#'"
                    v-model="rg"
                    label="RG (Opcional)"
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-3">
                <validation-provider
                  v-slot="{ errors }"
                  name="CPF"
                  rules="required"
                >
                  <v-text-field
                    v-mask="'###.###.###-##'"
                    v-model="cpf"
                    label="CPF (Obrigatório)"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
                <p style="color: red" v-if="errorMessage">
                  CPF digitado já em uso
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 mt-4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Sexo "
                  rules="required"
                >
                  <b-form-select
                    v-model="sexo"
                    :error-messages="errors"
                    :options="options2"
                    required
                  ></b-form-select>
                </validation-provider>
              </div>

              <div class="col-md-3 mt-4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Estado civil"
                  rules="required"
                >
                  <b-form-select
                    v-model="estadoCivil"
                    :error-messages="errors"
                    :options="options"
                    required
                  ></b-form-select>
                </validation-provider>
              </div>
              <div class="col-md-3">
                <validation-provider
                  v-mask="['(##) ####-####', '(##) #####-####']"
                  v-slot="{ errors }"
                  name="Telefone"
                  rules="required"
                >
                  <v-text-field
                    v-model="telefone"
                    label="Telefone (Obrigatório)"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Data de nascimento"
                  rules="required"
                >
                  <v-text-field
                    type="date"
                    v-model="dataNascimento"
                    label="Data de nascimento (Obrigatório)"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <validation-provider name="CEP">
                  <v-text-field
                    v-mask="'#####-###'"
                    @keyup="consultaCep"
                    v-model="cep"
                    label="CEP (Opcional)"
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-md-4">
                <validation-provider name="Município">
                  <v-text-field
                    v-model="municipio"
                    label="Município (Opcional)"
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-md-4">
                <validation-provider name="Estado">
                  <v-text-field
                    v-model="estado"
                    label="Estado (Opcional)"
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3">
                <validation-provider name="Rua">
                  <v-text-field
                    v-model="rua"
                    label="Rua (Opcional)"
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-3">
                <validation-provider name="Número">
                  <v-text-field
                    v-model="numero"
                    label="numero (Opcional)"
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-3">
                <validation-provider name="Bairro">
                  <v-text-field
                    v-model="bairro"
                    label="bairro (Opcional)"
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-md-3 mt-4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Prioridade"
                  rules="required"
                >
                  <b-form-select
                    v-model="prioridade"
                    :error-messages="errors"
                    :options="options3"
                    required
                  ></b-form-select>
                </validation-provider>
              </div>
            </div>

            <v-btn
              @click="registerPaciente"
              :disabled="invalid"
              class="mr-4 textStyle"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Cadastrar</span>
            </v-btn>

            <router-link to="/PainelPacientes" v-slot="{ href, navigate }">
              <a :href="href" class="menu-link" @click="navigate">
                <v-btn class="textStyle" tile color="#1dd1a1">
                  <span style="color: white">Voltar</span>
                </v-btn>
              </a>
            </router-link>
          </form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";
import { required } from "vee-validate/dist/rules";
import axios from "axios";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "O campo {_field_} precisa ser preenchido",
});
export default {
  name: "CadastroPacientes",
  directives: { mask },
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      errorMessage: false,
      prioridade: null,
      estado: "",
      cep: "",
      sexo: null,
      nomeSocial: "",
      nomeMae: "",
      nomePai: "",
      telefone: "",
      ubs: "",
      sus: "",
      prontuario: Date.now(),
      estadoCivil: null,
      rua: "",
      numero: "",
      bairro: "",
      municipio: "",
      nomeCompleto: "",
      dataNascimento: "",
      rg: "",
      cpf: "",
      sexoId: "1",
      options: [
        { value: null, text: "Selecione um status social (Obrigatório)" },
        { value: 5, text: "Solteiro(a)" },
        { value: 4, text: "Casado(a)" },
        { value: 1, text: "Divorciado(a)" },
        { value: 2, text: "Viúvo(a)" },
        { value: 3, text: "União Estável" },
        { value: 6, text: "Outro" },
      ],
      options2: [
        { value: null, text: "Selecione um gênero (Obrigatório)" },
        { value: 1, text: "Masculino" },
        { value: 2, text: "Feminino" },
        { value: 3, text: "Não informar" },
      ],
      options3: [
        { value: null, text: "Selecione uma prioridade (Obrigatório)" },
        { value: true, text: "Paciente com prioridade" },
        { value: false, text: "Paciente sem prioridade" },
      ],
    };
  },

  methods: {
    registerPaciente: async function () {
      var data = {
        paciente: {
          nome_social: this.nomeSocial,
          nome_mae: this.nomeMae,
          nome_pai: this.nomePai,
          telefone: this.telefone,
          ubs: this.ubs,
          sus: this.sus,
          prontuario: Math.floor(Math.random() * 65536),
          prioridade: this.prioridade,
          estado_civil_id: this.estadoCivil,
          endereco_attributes: {
            rua: this.rua,
            numero: this.numero,
            bairro: this.bairro,
            municipio: this.municipio,
            estado: this.estado,
          },
          pessoa_attributes: {
            nome: this.nomeCompleto,
            data_nascimento: this.dataNascimento,
            rg: this.rg,
            cpf: this.cpf,
            sexo_id: this.sexo,
          },
        },
      };

      try {
        this.errorMessage = false;
        await ambulatorio.registerPaciente(data);

        Swal.fire({
          title: "",
          text: "Cadastro realizado com sucesso",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false,
        });

        setTimeout(() => {
          this.$router.push({ name: "painelPacientes" });
        }, 1000);
      } catch (err) {
        this.errorMessage = true;
        Swal.fire({
          title: "",
          text: "Erro ao realizar cadastro, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false,
        });
      }
    },
    consultaCep: async function () {
      try {
        const response = await axios.get(
          `https://brasilapi.com.br/api/cep/v1/${this.cep}`
        );
        this.municipio = response.data.city;
        this.rua = response.data.street;
        this.bairro = response.data.neighborhood;
        this.estado = response.data.state;
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Cadastrar paciente" }]);
  },
};
</script>

<style>
.theme--light.v-messages {
  color: red !important ;
}
</style>
